import request from '@/service/request'

// 获取所有的organization
export function getOrganizationTree() {
  return request({
    url: 'api/organization/tree/',
    method: 'get'
  })
}

// 获取所有的user
export function getOrganizationUserTree() {
  return request({
    url: 'api/organization/user/tree/',
    method: 'get'
  })
}

// 导出分销商信息
export function exportDistributorUserList(data = {}) {
  return request({
    url: 'externaladmin/authService/user/exportDistributorUserList',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

export function add(data) {
  return request({
    url: 'api/organizations/',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'api/organizations/' + id + '/',
    method: 'delete'
  })
}

export function edit(id, data) {
  return request({
    url: 'api/organizations/' + id + '/',
    method: 'put',
    data
  })
}

// 导出交易记录
export function exportDistributorUserTradeRecordList(data = {}) {
  return request({
    url: 'externaladmin/authService/user/exportDistributorBillList',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
